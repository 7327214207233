import axios, { AxiosInstance, AxiosResponse } from "axios";
import { riderCityResponseProps } from "../@Types/DRO/RidersCitiesResponse";
import { merchantServiceabilityResponseProps } from "../@Types/DRO/MerchantServiceabilityResponse";
export class MerchantServiceabilityApi {
  public static api = new MerchantServiceabilityApi();
  private instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: "https://odd.bcykal.com",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("token");
  }

  merchantCitiesLocalityList(): Promise<
    AxiosResponse<merchantServiceabilityResponseProps>
  > {
    let apiUrl = "/mobycy/on-demand/dashboard/v1/get/all/city/merchant";
    return this.instance.get<merchantServiceabilityResponseProps>(apiUrl, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
  updateMerchantServiceability(data: any): Promise<any> {
    return this.instance
      .post<any>(
        `/mobycy/on-demand/dashboard/v1/update/merchant/serviceability`,
        data,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
